import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent, SyntheticEvent } from "react";
import { SelectChangeEvent } from "@mui/material";
import { API_URL } from "../config";
import { validateEmail } from "../utils";

const postUser = async (user: User) => {
  const newUser = { ...user };
  if (newUser.type == 0) {
    newUser.password = null;
  } else if (newUser.type == 1) {
    newUser.pin = null;
    newUser.tlc = null;
  }
  if (newUser.tlc && !newUser.pin) {
    newUser.pin = "7000";
  }
  if (newUser.type && !newUser.password) {
    newUser.password = "wrg23_Lkn";
  }

  const res = await fetch(`${API_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newUser),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const useAddUser = () => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState(0);
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [operator, setOperator] = useState({ id: null, label: "" });
  const [tlc, setTlc] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const successMessage = `User ${email} has been added!`;
  const setMessageAndResetPin = () => {
    fetch(`${API_URL}/users/reset/pin?tlc=${tlc}&operator=${operator?.label}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    setMesssage(`${successMessage} Reset pin email has been sent.`);
  }
  const setMessageAndResetPassword = () => {
    fetch(`${API_URL}/users/reset/password?email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    setMesssage(`${successMessage} Reset password email has been sent.`);
  }
  const setMessageAndResetPinAndPassword = () => {
    fetch(`${API_URL}/users/reset/password?email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    fetch(`${API_URL}/users/reset/pin?tlc=${tlc}&operator=${operator?.label}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    setMesssage(`${successMessage} Reset pin and password emails have been sent.`);
  }

  const mutation = useMutation(postUser, {
    onSuccess: () => {
      setIsModalOpen(false);
      queryClient.invalidateQueries('users');
      setError("");

      switch (type) {
        case 0: // User with only smartEFB access
          pin ? setMesssage(successMessage) : setMessageAndResetPin();
          break;
        case 1: // User with only FlightViewer access
          password ? setMesssage(successMessage) : setMessageAndResetPassword();
          break;
        case 2: // User with both accesses
          if (!password && !pin) {
            setMessageAndResetPinAndPassword();
          } else if (!password) {
            setMessageAndResetPassword();
          } else if (!pin) {
            setMessageAndResetPin();
          } else {
            setMesssage(successMessage);
          }
          break;
        default:
          setMesssage(successMessage);
      }
    },
    onError: (err: HttpError) => {
      setIsModalOpen(false);
      if (err.code === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
      setError("You can't add this user");
      setMesssage("");
    },
  });

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
    setMesssage("");
  };

  const onOperatorChange = (_: SyntheticEvent<Element, Event> | null, value: { id: any, label: any } | null) => {
    setOperator({ id: value?.id || "", label: value?.label || "" });
    setError("");
    setMesssage("");
  };

  const onTlcChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTlc(e.target.value);
    setError("");
    setMesssage("");
  };

  const onTypeChange = (e: SelectChangeEvent<number>) => {
    setType(Number(e.target.value));
    setError("");
    setMesssage("");
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError("");
    setMesssage("");
  };

  const onPinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
    setError("");
    setMesssage("");
  };

  const onIsAdminChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(e.target.checked);
    setMesssage("");
  };

  const handleSubmit = () => {
    mutation.mutate({ email, password, is_admin: isAdmin, operator_ref: operator.id || 0, tlc, pin, type });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleConfirmAddUser = (e: SyntheticEvent) => {
    e.preventDefault();
    setError("");
    setMesssage("");
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if ((type == 0 || type == 2) && (tlc.length === 0 || tlc.length > 4)) {
      setError("Please enter a valid TLC");
      return;
    }
    if (!!password && password.length < 4) {
      setError("Please enter a valid password");
      return;
    }
    if (!!pin && pin.length < 4) {
      setError("Please enter a valid pin");
      return;
    }
    if (!Number(operator.id)) {
      setError("Please select an operator");
      return;
    }
    setIsModalOpen(true);
  }

  return {
    email,
    onEmailChange,
    type,
    onTypeChange,
    password,
    onPasswordChange,
    tlc,
    pin,
    onPinChange,
    isAdmin,
    onIsAdminChange,
    operator,
    onOperatorChange,
    onTlcChange,
    handleSubmit,
    handleCloseModal,
    handleConfirmAddUser,
    isModalOpen,
    status: mutation.status,
    error,
    message,
  };
};

const deleteUser = async (id: string) => {
  const res = await fetch(`${API_URL}/users/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useDeleteUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
    onError: (error: Error) => {
      if (error.message === '401') {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
    },
  });
};

const postResetPassword = async ({ email }: User) => {
  const res = await fetch(`${API_URL}/users/reset/password?email=${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const useResetPassword = () => {
  const navigate = useNavigate();

  return useMutation(postResetPassword, {
    onSuccess: () => {
    },
    onError: (error: Error) => {
      if (error.message === '401') {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
    },
  });
};

const postResetPin = async ({ tlc, operator }: User) => {
  const res = await fetch(`${API_URL}/users/reset/pin?tlc=${tlc}&operator=${operator}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const useResetPin = () => {
  const navigate = useNavigate();

  return useMutation(postResetPin, {
    onSuccess: () => {
    },
    onError: (error: Error) => {
      if (error.message === '401') {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
    },
  });
};

const putUser = async ({ id, email, is_admin, type, tlc, pin, password }: User) => {
  const user: User = { id, email, is_admin, tlc, pin: pin ? pin : undefined, password: password ? password : undefined };
  if (type == 0) {
    user.password = null;
  } else if (type == 1) {
    user.pin = null;
    user.tlc = null;
  }
  const res = await fetch(`${API_URL}/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const usePutUser = () => {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState(0);
  const [dbUserType, setDbUserType] = useState(0);
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [tlc, setTlc] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(putUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      setError("");
      setDbUserType(type);
      setPassword("");
      setPin("");
      setMesssage(`User ${email} has been updated!`);
    },
    onError: (err: HttpError) => {
      if (err.code === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }

      setError("You can't update this user");
      setMesssage("");
    },
  });

  const setUser = (user: User) => {
    setType(user.type || 0)
    setDbUserType(user.type || 0)
    setPassword("");
    setPin("");
    setUserId(user.id || "");
    setEmail(user.email);
    setTlc(user.tlc || "");
    setIsAdmin(user.is_admin);
    setError("");
    setMesssage("");
  }

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
    setMesssage("");
  };

  const onTlcChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTlc(e.target.value);
    setError("");
    setMesssage("");
  };

  const onTypeChange = (e: SelectChangeEvent<number>) => {
    setType(Number(e.target.value));
    setError("");
    setMesssage("");
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError("");
    setMesssage("");
  };

  const onPinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
    setError("");
    setMesssage("");
  };

  const onIsAdminChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(e.target.checked);
    setMesssage("");
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setError("");
    setMesssage("");
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if ((type == 0 || type == 2) && (tlc.length === 0 || tlc.length > 4)) {
      setError("Please enter a valid TLC");
      return;
    }
    if ((!password && dbUserType == 0 && type !== dbUserType)) {
      setError("Please enter a valid password");
      return;
    }
    if ((!!password && password.length < 4)) {
      setError("Please enter a valid password");
      return;
    }
    if ((!pin && dbUserType == 1 && type !== dbUserType)) {
      setError("Please enter a valid PIN");
      return;
    }
    if (!!pin && pin.length < 4) {
      setError("Please enter a valid PIN");
      return;
    }
    mutation.mutate({ id: userId, email, is_admin: isAdmin, tlc: tlc || null, pin, password, type });
  }

  return {
    setUser,
    email,
    onEmailChange,
    tlc,
    onTlcChange,
    type,
    dbUserType,
    onTypeChange,
    password,
    onPasswordChange,
    pin,
    onPinChange,
    isAdmin,
    onIsAdminChange,
    onSubmit,
    status: mutation.status,
    error,
    message,
  };
};
